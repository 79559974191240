import service from '../utils/request'

export function findTaskAlarm(taskId, num, page, pageSize, versions) {
  return service({
    method: 'get',
    url: `/alarmOverview${versions === 3 ? 3 : ''}/findBy`,
    params: {
      taskId,
      num,
      page,
      pageSize,
    },
  })
}

export function findUserAlarm(userId, num, page, pageSize, versions) {
  return service({
    method: 'get',
    url: `/alarmOverview${versions === 3 ? 3 : ''}/findByUser`,
    params: {
      userId,
      num,
      page,
      pageSize,
    },
  })
}

export function updateAlarm(id, fkTaskId, processingConditions, versions) {
  return service({
    method: 'put',
    url: `/alarmOverview${versions === 3 ? 3 : ''}/update`,
    data: {
      id,
      fkTaskId,
      processingConditions,
    },
  })
}

// 对接 对接 对接 对接 对接 对接

// 获取待对接报警数据
export function getDocking(taskId, versions) {
  return service({
    method: 'get',
    // url: `/alarmOverview/getNewDocking`,
    url: `/alarmOverview${versions === 3 ? 3 : ''}/getDocking`,
    params: {
      taskId,
    },
  })
}
// 处理报警  对接 对接 上报数据
export function abutting(id, versions) {
  return service({
    method: 'get',
    // url: `/docking/alarmDocking`,
    url: `/docking/alarm${versions === 3 ? 3 : ''}Docking`,
    params: { id },
  })
}
// 批量移除待对接报警数据
export function clearAbutList(id, versions) {
  return service({
    method: 'put',
    url: `/alarmOverview${versions === 3 ? 3 : ''}/updateDocking`,
    params: { id },
  })
}
// start recover pause end endAccident
export function TaskDocking(taskId, type) {
  return service({
    method: 'get',
    url: `/docking/${type}TaskDocking`,
    params: {
      taskId,
    },
  })
}

// 处理数据异常
export function abnormalValueDel(protocol, ids) {
  return service({
    method: 'put',
    url: `/alarm/updateData${protocol}`,
    params: { ids },
  })
}
