<template>
  <div class="TaskJob">
    <el-row>
      <!-- <el-date-picker v-model="timeQuantum" type="daterange" class="mr_20" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"> </el-date-picker> -->
      <!-- <el-input v-model="projectName" class="mr_20" placeholder="请输入工程名称查询">
        <el-button slot="append" icon="el-icon-search" @click="findUnitAlarmByUserFun">查询</el-button>
      </el-input> -->
      <!-- 处理结果 -->
      <!-- <el-select v-model="itemType" placeholder="请选择监测类别" class="mr_20">
        <el-option label="轴力监测" value="F"> </el-option>
        <el-option label="倾角监测" value="L"> </el-option>
        <el-option label="位移监测" value="H"> </el-option>
        <el-option label="沉降监测" value="V"> </el-option>
      </el-select>
      <el-select v-model="value" placeholder="请选择处理结果" class="mr_20">
        <el-option label="真实警情" :value="1"> </el-option>
        <el-option label="伪报警" :value="2"> </el-option>
      </el-select>

      <el-button @click="findUnitAlarmByUserFun">查询</el-button> -->

      <el-select v-model="versions" placeholder="请选择节点版本" class="mr_20">
        <el-option label="versions2&2.5" :value="2"> </el-option>
        <el-option label="versions3" :value="3"> </el-option>
      </el-select>

      <el-button @click="findUnitAlarmByUserFun" type="primary">查询</el-button>
    </el-row>
    <MyTable ref="myTable" :dataSource="dataSource" :columns="columns" index border stripe :loading="loading" :total="total" :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" @handleClick="handleClick"></MyTable>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import { findUserAlarm } from '@/api/Alarm.js'
const columns = [
  {
    prop: 'projectName',
    label: '工程名称',
    type: 'text',
    width: '300px',
    align: 'center',
  },
  {
    prop: 'pointName',
    label: '监测节点',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '报警时间',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'safeState',
    label: '报警等级',
    type: 'icon',
    align: 'center',
  },
  {
    prop: 'handleTime',
    label: '处理时间',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'processingConditions',
    label: '处理结果',
    type: 'result_text',
    align: 'center',
  },
]
const dataSource = []
export default {
  name: 'AlarmList',
  components: { MyTable },
  data() {
    return {
      versions: 2,
      value: '',
      projectName: null,
      currentPage: 0,
      pageSize: 10,
      total: null,
      loading: true,
      dataSource,
      columns,
      stateId: 0,
      pkTaskId: null,
      hostId: null,
      hostList: [],
      disabled: false,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      timeQuantum: '',
      itemType: 'F',
    }
  },

  methods: {
    async findUnitAlarmByUserFun() {
      this.loading = true
      const { data: res } = await findUserAlarm(this.$store.state.userId, 1, this.currentPage, this.pageSize, this.versions)
      console.log(res.data.list)
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point.pointName
        item.projectName = item.task.projectName
      })
      this.dataSource = res.data.list
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    handleSizeChange(val) {
      this.pageSize = val

      this.findUnitAlarmByUserFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findUnitAlarmByUserFun()
    },
    handleClick() {},
    handleSelectItem() {},
  },
  created() {
    this.findUnitAlarmByUserFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.TaskJob {
  padding: 10px;
  width: calc(100% - 20px);
  .el-row {
    min-width: 1600px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .mr_20 {
    margin-right: 20px;
  }
  .el-input {
    width: 300px;
  }
}

.addTask ::v-deep .el-dialog {
  width: 800px;
  height: 600px;
}
.upload ::v-deep .el-dialog {
  width: 500px;
  height: 800px;
}
.upState ::v-deep .el-dialog {
  width: 350px !important;
  height: 240px;
}

.TaskInfo ::v-deep .el-dialog {
  width: 1360px;
  height: 1000px;

  .el-dialog__body {
    padding: 0 25px;
  }
}

.TaskJob {
  ::v-deep .el-message-box__content {
    height: 200px !important;
  }
}

.el-message-box__message {
  height: 200px;
}
</style>
